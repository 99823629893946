import ReplacementCard from "../constants/ReplacementCard.constant";

const initialState = {
    replacementCards: [],
    getLoading: false,
    metaData: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ReplacementCard.GET_API:
            return {
                ...state,
                replacementCards: action.data,
                getLoading: action.loading,
                metaData: action.metaData
            };

        default:
            return state;
    }
};