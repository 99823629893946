import auth from "./Auth.reducer";
import dashboard from "./Dashboard.reducer";
import employees from "./Employees.reducer";
import common from "./Common.reducer";
import user from "./User.reducer";
import child from "./Childs.reducer";
import salaries from "./Salaries.reducer";
import transactions from "./Transactions.reducer";
import requests from "./Requests.reducer";
import approval from "./Approvals.reducer";
import centivCard from "./CentivCard.reducer";
import notifications from "./Notifications.reducer";
import advanceSalary from "./AdvanceSalary.reducer";
import salaryStatus from "./SalaryStatus.reducer";
import establishment from "./Establishments.reducer";
import replacementCard from "./ReplacementCard.reducer";

export default {
  auth,
  dashboard,
  employees,
  common,
  user,
  child,
  salaries,
  transactions,
  requests,
  approval,
  centivCard,
  notifications,
  advanceSalary,
  salaryStatus,
  establishment,
  replacementCard
};
