import DASHBOARD from "../constants/Dashboard.constant";

const initialState = {
    data: [],
    loading: false,

    getReportLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case DASHBOARD.GET_ALL:
            return {
                ...state,
                loading: action.loading,
                data: action.data,
            };

        case DASHBOARD.GET_REPORT:
            return {
                ...state,
                getReportLoading: action.loading,
            };

        default:
            return state;
    }
};
