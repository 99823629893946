import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CButton } from "../../uiComponents";
import EditForm from "./EditForm";

const CEditEmpCode = ({ employee }) => {

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.editLoading,
        };
    });

    const { loading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);

    return <>
        <CButton
            containerClass="flex0"
            buttonType="gradient"
            title="Edit Emp Code"
            disabled={loading}
            loading={loading}
            onClick={() => setIsOpen(true)}
            leftIcon={"edit"}
        />
        <EditForm isOpen={isOpen} setIsOpen={setIsOpen} loading={loading} employee={employee} />
    </>
};

export default CEditEmpCode;