import REQUESTS from "../constants/Requests.constant";

const initialState = {
  getLoading: true,
  requests: [],
  metaData: {},
  getSingleRequestLoading: false,
  request: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUESTS.GET_REQUESTS:
      return {
        ...state,
        getLoading: action.loading,
        requests: action.data || state.data,
        metaData: action.metaData || state.metaData
      };

    case REQUESTS.GET_SINGLE_REQUEST:
      return {
        ...state,
        getSingleRequestLoading: action.loading,
        request: action.data,
      };

    default:
      return state;
  }
};
