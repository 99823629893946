import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CButton } from "../../uiComponents";
import { CiPassport1 } from "react-icons/ci";
import AddForm from "./AddForm";

const CEditEmpCode = ({ employeeId }) => {

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.addPassportLoading,
        };
    });

    const { loading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);

    return <>
        <CButton
            containerClass="flex0"
            buttonType="gradient"
            title="Add/Update Passport"
            disabled={loading}
            loading={loading}
            onClick={() => setIsOpen(true)}
            leftIcon={(className) => <CiPassport1 className={className} />}
        />
        <AddForm isOpen={isOpen} setIsOpen={setIsOpen} loading={loading} employeeId={employeeId} />
    </>
};

export default CEditEmpCode;