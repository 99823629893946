import React, { memo } from "react";
import { Modal } from "antd";
import { renderSalaryIssues } from "./helper";

function InvalidEmployees({ isOpen, setIsOpen, invalidateEmployees }) {

    return (
        <Modal
            title="Invalid Employees"
            width={1000}
            centered
            open={isOpen}
            closable={true}
            maskClosable={false}
            onCancel={() => setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
        >
            {renderSalaryIssues(invalidateEmployees)}
        </Modal>
    );
}

export default memo(InvalidEmployees);