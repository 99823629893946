import { NATIONALITY, TOPIC } from "../../utils/constants";
import { MappedElement, getObjByKey, getStringFromBool, readableText, readableTextCap, renderDate } from "../../utils/methods";

export const EMPLOYEES_TOPIC = [
    "EMPLOYEES_FILE_UPLOAD",
    "EMPLOYEE_ONBOARD_REQUEST",
    "EMPLOYEES_OTHER_BANK_FILE_UPLOAD",
    "EMPLOYEE_ONBOARD_APPROVED",
    "EMPLOYEE_ONBOARD_REJECT",
    "EMPLOYEE_UPDATE_REQUEST",
    "CARD_REPLACEMENT_REQUEST",
    "EMPLOYEE_APPLICATION_BLOCK_REQUEST",
    "EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST",
    "USER_BLOCK_REQUEST",
    "CHANGE_EMPLOYEE_TYPE",
    "CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY",
    "USER_UNBLOCK_REQUEST",
    "UPDATE_MOL_NO",
    "EMPLOYEE_PHONE_UPDATE",
    "CARD_PIN_CHANGE",
    "EMPLOYEE_STATEMENT_REQUEST",
    "INACTIVE_EMPLOYEES",
    "EMPLOYEE_EID_UPDATE",
    "UPDATE_DATE_OF_BIRTH",
    "UPDATE_NATIONALITY",
    "UPDATE_PASSPORT",
    "UPDATE_EMP_CODE",
    'TEAM_MEMBER_PHONE_UPDATE'
];
export const ESTABLISHMENT_TOPIC = [
    "ESTABLISHMENT_ID_ADDED",
    "ESTABLISHMENT_IDS_ADDED",
    "BULK_ESTABLISHMENT_IDS_ADDED",
];
const CLIENT_TOPIC = [
    "CLIENT_PROFILE_UPDATE",
    "INITIAL_VERIFICATION_APPROVED",
    "ON_BOARD_REQUEST",
    "ON_BOARD_REQUEST_APPROVED",
    "ON_BOARD_REQUEST_REJECT",
];
export const SALARY_TOPIC = [
    "SALARY_FILE_UPLOAD",
    "DEPOSIT_FILE_UPLOAD",
    "EOS_FILE_UPLOAD",
];
export const CENTIV_TOPIC = [
    "CENTIV_CARD_REQUEST",
    "CENTIV_LOAD_FUNDS",
];

export const segregateTopic = (obj, user) => {

    let topics = [];

    if (user && Object.keys(user)?.length) {
        if (user?.isClient || obj.canAllChecker) {
            topics.push(...Object.keys(TOPIC))
        } else {
            if (obj.canEmployeesRequestChecker) {
                topics.push(...EMPLOYEES_TOPIC)
            }
            if (obj.canCentivCardRequestChecker) {
                topics.push(...CENTIV_TOPIC)
            }
            if (obj.canEstablishmentRequestChecker) {
                topics.push(...ESTABLISHMENT_TOPIC)
            }
            if (obj.canProcessSalariesRequestChecker) {
                topics.push(...SALARY_TOPIC)
            }
        }
    }

    return topics

};
export const topicWiseCheck = (obj, user, topic) => {

    return segregateTopic(obj, user)?.includes(topic)

};
export const thirdStepApprovalCheck = (topics = [], topic) => {

    return topics?.includes(topic)

};

export const renderItem = (title, value) => {
    if (value) {
        return (
            <li>
                <span>{title}</span>
                <span>{value}</span>
            </li>
        )
    }
};

export const reGenerateEmployeeInfo = (item, onlyShow = []) => {
    let infoArray = [];
    if (onlyShow?.length ? onlyShow?.includes('molNo') ? item?.molNo : null : item?.molNo) {
        infoArray.push({
            title: 'MOL Number',
            value: item?.molNo
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('eid') ? item?.eid : null : item?.eid) {
        infoArray.push({
            title: 'Emirates Id',
            value: item?.eid
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('displayName') ? item?.displayName : null : item?.displayName) {
        infoArray.push({
            title: 'Display Name',
            value: item?.displayName
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('email') ? item?.email : null : item?.email) {
        infoArray.push({
            title: 'Email',
            value: item?.email
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('establishmentId') ? item?.establishmentId : null : item?.establishmentId) {
        infoArray.push({
            title: 'Linked Establishment Id',
            value: item?.establishmentId
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('empCode') ? item?.empCode : null : item?.empCode) {
        infoArray.push({
            title: 'Emp Code',
            value: item?.empCode
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('passportNumber') ? item?.passportNumber : null : item?.passportNumber) {
        infoArray.push({
            title: 'Passport Number',
            value: item?.passportNumber
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('employeeType') ? item?.employeeType : null : item?.employeeType) {
        infoArray.push({
            title: 'Employee Type',
            value: readableText(item?.employeeType)
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('cardType') ? item?.cardType : null : item?.cardType) {
        infoArray.push({
            title: 'Card Type',
            value: readableText(item?.cardType)
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('mobileNo') ? item?.mobileNo : null : item?.mobileNo) {
        infoArray.push({
            title: 'Mobile Number',
            value: item?.mobileNo
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('altMobileNo') ? item?.altMobileNo : null : item?.altMobileNo) {
        infoArray.push({
            title: 'Alt Mobile Number',
            value: item?.altMobileNo
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('gender') ? item?.gender : null : item?.gender) {
        infoArray.push({
            title: 'Gender',
            value: item?.gender
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('dob') ? item?.dob : null : item?.dob) {
        infoArray.push({
            title: 'Date of Birth',
            value: renderDate(item?.dob)
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('doj') ? item?.doj : null : item?.doj) {
        infoArray.push({
            title: 'Date of Joining',
            value: renderDate(item?.doj)
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('nationality') ? item?.nationality : null : item?.nationality) {
        infoArray.push({
            title: 'Nationality',
            value: item?.nationality?.length === 2 ? getObjByKey(NATIONALITY, `${item?.nationality}`)?.name : item?.nationality
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('kamelpayWalletId') ? item?.kamelpayWalletId : null : item?.kamelpayWalletId) {
        infoArray.push({
            title: 'Wallet Id',
            value: item?.kamelpayWalletId
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('workAddress') ? item?.workAddress : null : item?.workAddress) {
        infoArray.push({
            title: 'Address',
            value: item?.workAddress
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('workStateId') ? item?.workStateId : null : item?.workStateId) {
        infoArray.push({
            title: 'State',
            value: item?.workStateId?.name
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('workZipCode') ? item?.workZipCode : null : item?.workZipCode) {
        infoArray.push({
            title: 'Zip Code',
            value: item?.workZipCode
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('havingMultipleAccounts') ? item?.havingMultipleAccounts : null : item?.havingMultipleAccounts) {
        infoArray.push({
            title: 'Multiple Accounts',
            value: getStringFromBool(item?.havingMultipleAccounts)
        })
    }
    if (item?.employeeType === "OTHER_BANK" || item?.havingMultipleAccounts) {
        if (onlyShow?.length ? onlyShow?.includes('otherBank_bankName') ? item?.otherBank?.bankName : null : item?.otherBank?.bankName) {
            infoArray.push({
                title: 'Bank Name',
                value: item?.otherBank?.bankName
            })
        }
        if (onlyShow?.length ? onlyShow?.includes('otherBank_branchName') ? item?.otherBank?.branchName : null : item?.otherBank?.branchName) {
            infoArray.push({
                title: 'Branch Name',
                value: item?.otherBank?.branchName
            })
        }
        if (onlyShow?.length ? onlyShow?.includes('otherBank_iban') ? item?.otherBank?.iban : null : item?.otherBank?.iban) {
            infoArray.push({
                title: 'IBAN',
                value: item?.otherBank?.iban
            })
        }
    }
    return infoArray
};

export const generateRenderingData = (renderKeys = [], array = []) => {
    return array?.map((obj, i) => {
        return renderKeys.map(keyObj => {
            const key = keyObj?.key;
            const title = keyObj?.title;
            return {
                title: title ? title : readableTextCap(key || ''),
                value: keyObj?.render ? keyObj?.render(obj[key]) : obj[key],
                ...keyObj
            }
        });
    });
}

export const renderGeneratedData = (data = [], render = () => null, docKey = '', renderDocuments = () => null) => {
    return <MappedElement
        data={data}
        renderElement={(array) => <MappedElement data={array} renderElement={(obj) => {
            if (obj?.title === docKey && obj?.value?.length) {
                return renderDocuments(obj?.value);
            } else if (obj?.title !== docKey && obj?.value) {
                return <>
                    {render(obj?.title, obj?.value)}
                    {obj?.after && <hr />}
                </>
            }
        }}
        />}
    />
}