const EMPLOYEES = {
  GET_EMPLOYEES: "GET_EMPLOYEES",
  UPLOAD_EMPLOYEE: "UPLOAD_EMPLOYEE",
  UPLOAD_OTHER_BANK_EMPLOYEES: "UPLOAD_OTHER_BANK_EMPLOYEES",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  EDIT_EMPLOYEE: "EDIT_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",


  BLOCK_ACTIVE_REQUEST: "BLOCK_ACTIVE_REQUEST",
  GET_EMPLOYEE_DETAILS: "GET_EMPLOYEE_DETAILS",
  DOWNLOAD_LIST: "DOWNLOAD_LIST",
  CHANGE_BANK_TYPE: "CHANGE_BANK_TYPE",

  GET_EMPLOYEE_TRANSACTION: "GET_EMPLOYEE_TRANSACTION",

  BLOCK_APP: "BLOCK_APP",
  ACTIVATE_APP: "ACTIVATE_APP",
  REPLACE_CARD: "REPLACE_CARD",

  GET_EMPLOYEE_TRANSACTION_OTHER_BANK: "GET_EMPLOYEE_TRANSACTION_OTHER_BANK",

  GET_BALANCE: "GET_BALANCE",
  CHECK_CARD_STATUS: "CHECK_CARD_STATUS",
  GET_APP_TRANSACTIONS: "GET_APP_TRANSACTIONS",

  UPDATE_EID: "UPDATE_EID",

  EMPLOYEE_STATEMENT_REQUEST: "EMPLOYEE_STATEMENT_REQUEST",
  CARD_ACTIVATE_OR_BLOCK: "CARD_ACTIVATE_OR_BLOCK",

  OCR_EID: "OCR_EID",

  GET_EID_FULL_DETAIL: "GET_EID_FULL_DETAIL",

  UPDATE_DIRECT_EID: "UPDATE_DIRECT_EID",

  SEND_OTP: 'SEND_OTP',

  ACTIVATE_CARD: 'ACTIVATE_CARD',

  CHANGE_PIN: "CHANGE_PIN",

  INACTIVE_EMPLOYEES: "INACTIVE_EMPLOYEES",

  CHECK_SUBSCRIPTION_STATUS: "CHECK_SUBSCRIPTION_STATUS",

  WHITELIST_BLACKLIST: "WHITELIST_BLACKLIST",

  SYNC_EMPLOYEES: "SYNC_EMPLOYEES",

  SWITCH_TO_KAMELPAY: "SWITCH_TO_KAMELPAY",

  UPDATE_DOB: "UPDATE_DOB",

  PROCESS_BULK_DEACTIVATION_FILE: "PROCESS_BULK_DEACTIVATION_FILE",

  BULK_EMPLOYEES_DEACTIVATION: "BULK_EMPLOYEES_DEACTIVATION",

  ADD_PASSPORT: "ADD_PASSPORT",

};

export default EMPLOYEES;
