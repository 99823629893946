import React, { Fragment } from "react";
import { Navbar } from "../index";
import {CLoading} from "../../uiComponents";

function PageWrapper({
    children,
    headerProps,
    background = true,
    className,
    noNavar = false,
    renderBelow,
    loadingWithOverlay = false,
    loading
}) {
    return  <Fragment>
        {noNavar ? null : <Navbar {...headerProps} />}
        {renderBelow}
        <div className={`${background ? "page" : "normal-page"} ${className || ""}`}>
            {loadingWithOverlay ? <>
                    <CLoading loading={loading} position={'absolute'}/>
                    {children}
                </>
                : loading ? <CLoading position={'absolute'}/> : children}

        </div>
    </Fragment>;
}
export default React.memo(PageWrapper);
