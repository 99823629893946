import ESTABLISHMENTS from "../constants/Establishments.constant";

const initialState = {
    getLoading: false,
    establishments: [],
    metaData: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case ESTABLISHMENTS.GET_ESTABLISHMENTS:
            return {
                ...state,
                getLoading: action.loading,
                establishments: action.data,
                metaData: action.metaData
            };

        default:
            return state;
    }

};