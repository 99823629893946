import NOTIFICATIONS from "../constants/Notifications.constant";
import { post } from "../../utils/apiMethods";
import { handleError } from "../../utils/methods";

export const getNotifications = (payload) => async (dispatch) => {
  dispatch({ type: NOTIFICATIONS.GET_NOTIFICATIONS, loading: true });
  post(`notifications/getAll`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: NOTIFICATIONS.GET_NOTIFICATIONS,
          loading: false,
          metaData: data?.data?.data?.metaData,
          data: data?.data?.entries,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: NOTIFICATIONS.GET_NOTIFICATIONS, loading: false, data: [], metaData: {} });
      }
    })
    .catch((error) => {
      dispatch({ type: NOTIFICATIONS.GET_NOTIFICATIONS, loading: false, data: [], metaData: {} });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};
