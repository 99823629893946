const CHILDS = {

    GET_CHILDS: 'GET_CHILDS',
    ADD_CHILD: 'ADD_CHILD',
    UPDATE_CHILD: 'UPDATE_CHILD',


};

export default CHILDS;
