import { lazy } from 'react'

const Login = lazy(() => import("./auth/login/Login"));
const Branch = lazy(() => import("./auth/branch/Branch"));
const SignUp = lazy(() => import("./auth/signup/SignUp"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Profile = lazy(() => import("./profile"));
const Settings = lazy(() => import("./profile/Settings"));
const Salaries = lazy(() => import("./salaries/Salaries"));
const Employees = lazy(() => import("./employees/Employees"));
const AddEmployee = lazy(() => import("./employees/add/Add"));
const EmployeeDetailsUpdated = lazy(() => import("./employees/updatedDetails/Details"));
const AllTransactions = lazy(() => import("./employees/updatedDetails/AllTransactions"));
const ResetPassword = lazy(() => import("./auth/resetPass/resetPassword"));
const Otp = lazy(() => import("./auth/otp/Otp"));
const Forgetpassword = lazy(() => import("./auth/forgetpassword/Forget"));
const TermsAndPolicies = lazy(() => import("./auth/TermsAndPolicies"));
const User = lazy(() => import("./users"));
const ManageAccess = lazy(() => import("./users/Manage"));
const Childs = lazy(() => import("./childs"));
const AddChild = lazy(() => import("./childs/add/Add"));
const ChildDetails = lazy(() => import("./childs/details/Details"));
const Transactions = lazy(() => import("./transactions/Transactions"));
const AllRequests = lazy(() => import("./requests/AllRequests"));
const Notifications = lazy(() => import("./notifications/Notifications"));
const CentivCard = lazy(() => import("./centivCard/CentivCard"));
const CentivCardDetails = lazy(() => import("./centivCard/CentivCardDetails"));
const Requests = lazy(() => import("./requests/Requests"));
const AdvanceSalary = lazy(() => import("./advanceSalary/AdvanceSalary"));
const SalaryStatus = lazy(() => import("./salaryStatus/SalaryStatus"));
const LinkedEstablishments = lazy(() => import("./linkedEstablishments"));
const ReplacementCards = lazy(() => import("./replacementCards"));
const AuthTwoFactor = lazy(() => import("./auth/authenticationTwoFactor/authTwoFactor"));

export {
  Login,
  AuthTwoFactor,
  Branch,
  SignUp,
  Salaries,
  AllTransactions,
  Otp,
  AllRequests,
  Forgetpassword,
  Requests,
  Dashboard,
  Settings,
  Profile,
  ResetPassword,
  Employees,
  AddEmployee,
  EmployeeDetailsUpdated,
  TermsAndPolicies,
  User,
  ManageAccess,
  Childs,
  Transactions,
  AddChild,
  ChildDetails,
  Notifications,
  CentivCard,
  CentivCardDetails,
  AdvanceSalary,
  SalaryStatus,
  LinkedEstablishments,
  ReplacementCards
};
