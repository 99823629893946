import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Modal, Popconfirm } from "antd";
import { getLength, renderSheet } from "./helper";
import { CButton } from "../../uiComponents";

function ValidEmployees({ isOpen, setIsOpen, validateEmployees, invalidateEmployees, setIsOpenInvalid, submitDeactivateEmployees }) {

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.deactivateBulkEmployeesLoading,
        };
    });

    const { loading } = reduxState;

    return <Modal
        title="Valid Employees for deactivation"
        width={1000}
        centered
        open={isOpen}
        closable={false}
        maskClosable={false}
        onCancel={() => setIsOpen(false)}
        classNames={{ footer: 'd-flex justify-end align-center' }}
        footer={[
            <Popconfirm
                title='Are you sure you want to close the modal?'
                onConfirm={() => setIsOpen(false)}
                onCancel={() => console.log("cancel")}
                okText="Proceed"
                cancelText="Cancel"
            >
                <CButton
                    buttonLayout="primary-outline"
                    containerClass="flex0"
                    title="Close"
                    type="button"
                    disabled={loading}
                />
            </Popconfirm>,
            <CButton
                buttonLayout="primary"
                containerClass="flex0"
                title="Submit"
                type="button"
                onClick={submitDeactivateEmployees}
                disabled={loading || !(getLength(validateEmployees))}
                loading={loading}
            />
        ]}
    >
        {renderSheet(validateEmployees, invalidateEmployees, setIsOpenInvalid, loading)}
    </Modal>
}

export default memo(ValidEmployees);