import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useRoutes } from "react-router-dom";
import {
  Login,
  Branch,
  Dashboard,
  SignUp,
  ResetPassword,
  Otp,
  Forgetpassword,
  Employees,
  AddEmployee,
  EmployeeDetailsUpdated,
  AllTransactions,
  Profile,
  TermsAndPolicies,
  User,
  ManageAccess,
  AddChild,
  Childs,
  ChildDetails,
  Salaries,
  Transactions,
  Settings,

  Requests,
  CentivCard,
  CentivCardDetails,
  AdvanceSalary,
  SalaryStatus,
  LinkedEstablishments,
  ReplacementCards,
  AuthTwoFactor,
} from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { SideMenu } from "../containers";
import { Navigate } from "react-router";
import { changeMainClient, getAllClients, getAllTopics, getStates, toggleLeftDrawer } from "../store/actions/Common.action";
import { KYC } from "../utils/constants";
import {
  home,
  employees,
  multiuser,
  profileUser,
  transactionIcon,
  processSalaries,
  requestIcon,
  userCheck,
} from "../assets/svg";
import { ACL_ACCESS_LIST, ACL_MODULES } from "../utils/acl";
import { CRefreshModal } from "../components";
import { CLoading } from "../uiComponents";

function Auth() {
  return (
    <Suspense fallback={<CLoading />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/branch" element={<Branch />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/auth" element={<AuthTwoFactor />} />
        <Route path="/forgetpassword" element={<Forgetpassword />} />
        <Route path="/termsandpolicies" element={<TermsAndPolicies />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
}

function Root(props) {
  const stateProps = useSelector(({ common, auth, approval }) => {
    return {
      user: auth.user,
      accessRights: auth?.accessRights || [],
      isOpenLeftDrawer: common.isOpenLeftDrawer,
      pendingApprovals: approval.pendingApprovals,
    };
  });

  const { user, accessRights, isOpenLeftDrawer } = stateProps;

  const adminRoutes = useMemo(() => [
    // {
    //   key: "REDIRECT",
    //   path: "*",
    //   element: <Navigate to={"/"} replace />,
    //   hideInMenu: true,
    // },
    ...((user?.isClient ? KYC?.ON_BOARD_REQUEST_APPROVED === user?.kyc : true)
      ? [
        {
          key: ACL_MODULES.DASHBOARD,
          title: "Dashboard",
          icon: home,
          path: "/",
          exact: true,
          element: <Dashboard />,
        },
        {
          key: ACL_MODULES.MANAGE_USERS,
          title: "Team Members",
          icon: userCheck,
          path: "user",
          exact: true,
          permissions: [],
          element: <User />,
        },
        {
          key: ACL_ACCESS_LIST.MANAGE_ACCESS_USER,
          title: "Manage Access",
          index: false,
          path: "user/manage-access",
          exact: true,
          element: <ManageAccess />,
          hideInMenu: true,
        },
        {
          key: ACL_MODULES.EMPLOYEES,
          title: "Employees",
          icon: employees,
          path: "employee",
          exact: true,
          element: <Employees />,
        },
        {
          key: ACL_MODULES.CENTIV_CARD,
          title: "Centiv Card",
          icon: processSalaries,
          path: "centivCard",
          exact: true,
          element: <CentivCard />,
        },
        {
          key: ACL_ACCESS_LIST.CENTIV_DETAIL_VIEW,
          title: "Centiv Card Details Page",
          icon: processSalaries,
          hideInMenu: true,
          path: "centivCard/details",
          exact: true,
          element: <CentivCardDetails />,
        },
        {
          key: ACL_ACCESS_LIST.ADD_EMPLOYEES,
          title: "Add Employee",
          index: false,
          path: "employee/add",
          exact: true,
          element: <AddEmployee />,
          hideInMenu: true,
        },
        {
          key: ACL_ACCESS_LIST.EMPLOYEE_DETAIL_VIEW,
          title: "Employee Details",
          index: false,
          path: `employee/view`,
          exact: true,
          element: <EmployeeDetailsUpdated />,
          hideInMenu: true,
        },

        {
          key: ACL_MODULES.TRANSACTIONS,
          title: "Transactions",
          icon: transactionIcon,
          path: "transactions",
          exact: true,
          element: <Transactions />,
        },
        ...(user?.employeeTransactionsAccess
          ? [
            {
              key: "EMPLOYEES",
              title: "All Transactions",
              icon: transactionIcon,
              path: "employee/allTransactions",
              exact: true,
              hideInMenu: true,
              element: <AllTransactions />,
            },
          ] : []),
        ...(user?.isParentCompany
          ? [
            {
              key: ACL_MODULES.MY_OTHER_COMPANIES,
              title: "My Other Companies",
              icon: multiuser,
              path: "companies",
              exact: true,
              element: <Childs />,
            },
            {
              key: ACL_ACCESS_LIST.ADD,
              title: "Add Child",
              index: false,
              path: "companies/add",
              exact: true,
              element: <AddChild />,
              hideInMenu: true,
            },
            {
              key: "CHILDS_DETAILS_VIEW",
              title: "Child Details",
              index: false,
              path: "childs/details",
              exact: true,
              element: <ChildDetails />,
              hideInMenu: true,
            },
          ] : []),
        {
          key: ACL_MODULES.REQUESTS,
          title: "Requests",
          icon: requestIcon,
          path: "/requests",
          exact: true,
          element: <Requests />,
          showTag: true,
        },
        // {
        //   key: "ADVANCE_SALARY_VIEW",
        //   title: "Advance Salary",
        //   icon: requestIcon,
        //   path: "/advanceSalary",
        //   exact: true,
        //   element: <AdvanceSalary />,
        // },
        {
          key: ACL_MODULES.SALARY_STATUS,
          title: "Salary Status",
          icon: processSalaries,
          path: "salaryStatus",
          exact: true,
          element: <SalaryStatus />,
          permissions: [],
        },
        {
          key: ACL_MODULES.REPLACEMENT_CARDS,
          title: "Replacement Cards",
          icon: processSalaries,
          path: "replacementCards",
          exact: true,
          element: <ReplacementCards />,
        },
        {
          key: ACL_MODULES.ESTABLISHMENT,
          title: "Establishments",
          icon: processSalaries,
          path: "linked-establishments",
          exact: true,
          element: <LinkedEstablishments />,
        },
        {
          key: ACL_MODULES.PROCESS_SALARIES,
          title: "Process Salaries / Fund Transfer Receipt",
          icon: processSalaries,
          path: "processSalaries",
          exact: true,
          element: <Salaries />,
        },
      ] : []),
    {
      key: "ALL",
      title: "Profile",
      icon: profileUser,
      path:
        KYC.ON_BOARD_REQUEST === user?.kyc ||
          KYC.INITIAL_VERIFICATION_APPROVED === user?.kyc ||
          KYC.ON_BOARD_REQUEST_REJECT === user?.kyc
          ? "/"
          : "/profile",
      exact: true,
      element: <Profile />,
      permissions: [],
    },
    {
      key: "SETTINGS",
      title: "Settings",
      index: false,
      path: "/settings",
      exact: true,
      element: <Settings />,
      hideInMenu: true,
    },
    // {
    //   key: "NOTIFICATIONS",
    //   title: "Notifications",
    //   index: false,
    //   path: "/notifications",
    //   exact: true,
    //   element: <Notifications />,
    //   hideInMenu: true,
    // },
  ], [user, accessRights]);

  const [routesState, updateRoutesState] = useState([]);

  const routes = useRoutes(routesState);
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    if (props?.history && props?.history?.location) {
      history(`${props?.history?.location?.pathname}${props?.history?.location?.search}`, { replace: true, state: props?.history?.location?.state });
    }

    dispatch(getStates());
    if (user?.isParentCompany) {
      let payload = {
        kyc: 'ON_BOARD_REQUEST_APPROVED'
      }
      dispatch(getAllClients(payload));
    }
    (async () => {
      const selectedClient = localStorage.getItem('CLIENT');
      if (selectedClient) {
        dispatch(changeMainClient(selectedClient))
      }
    })();
    dispatch(getAllTopics());
  }, []);


  const filterRoute = useCallback((array) => array.filter((obj) => {
    if (obj?.children?.length) {
      const filteredChildren = obj?.children.filter((child) => accessRights.find((item) => child?.key === "ALL" ? obj : item?.key?.includes(obj?.key) || item?.access?.includes(obj?.key)));
      if (filteredChildren?.length) {
        obj.children = filteredChildren;
        return obj;
      }
    } else {
      // || obj?.key === 'MANAGE'
      return accessRights.find((item) => obj?.key === "ALL" ? obj : (item?.key?.includes(obj?.key) && item?.access?.includes(ACL_ACCESS_LIST.VIEW)) || item?.access?.includes(obj?.key))
    }
  }), [accessRights]);

  useEffect(() => {
    if (user?.isClient) {
      updateRoutesState(adminRoutes);
    } else if (user && accessRights && accessRights?.length) {
      updateRoutesState(filterRoute(adminRoutes));
    }
  }, [user]);

  const toggleLeft = useCallback(() => {
    dispatch(toggleLeftDrawer(!isOpenLeftDrawer));
  }, [dispatch, isOpenLeftDrawer]);

  const toggleClass = useMemo(() => isOpenLeftDrawer ? "left-drawer-open open" : "", [isOpenLeftDrawer]);

  return (
    <div className={`side-menu-with-body ${toggleClass}`}>
      <div className="side-menu-container" onClick={toggleLeft}>
        <SideMenu
          {...props}
          routes={routesState}
          user={user}
          currentPathName={
            props && props.location ? props.location.pathname : ""
          }
          activeStatus={stateProps?.pendingApprovals}
        />
      </div>
      <div className="body-container scroll-y">
        {routesState && routesState?.length ? (
          <Suspense fallback={<CLoading position="relative" />}>
            <Routes>
              {routesState.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              {routesState?.length ? (
                <Route
                  path="*"
                  element={<Navigate to={`${routesState[0]?.path}`} replace />}
                />
              ) : null}
            </Routes>
          </Suspense>
        ) : null}
        <CRefreshModal />
      </div>
    </div>
  );
}

export { Auth, Root };
