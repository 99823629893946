import React from 'react';
import { Skeleton } from 'antd';

const CSkeletonInput = ({ active = true, size = 'small', addClass = false, style = { width: 120, minWidth: 120 } }) => {
    return (
        <Skeleton.Input size={size} active={active} style={style} className={addClass ? `ant-skeleton-element-w100` : ''} />
    )
};

export default CSkeletonInput
