import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Space, Badge, Drawer } from "antd";
import { CField } from "../../uiComponents";
import { useNavigate } from "react-router";
import { IoIosMenu } from "react-icons/io";
import { ImArrowLeft2 } from "react-icons/im";
import { BsBell } from "react-icons/bs";
import NotificationDrawer from './NotificationDrawer';
import { getNotifications } from './../../store/actions/Notifications.action';
import { changeMainClient, toggleLeftDrawer } from "../../store/actions/Common.action";

function Navbar(props) {
  const {
    title = "",
    subTitle = "",
    subTitleToTitle,
    renderRight = () => null,
    canGoBack = false,
    navbarBg,
    showAjmanLogo = true,
    renderBelow,
    canRenderCompany = false,
    companyLoading = false,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenNotificationsDrawer, setIsOpenNotificationsDrawer] = useState(false);
  const toggleNotificationsDrawer = (val = false, obj = {}) => {
    setIsOpenNotificationsDrawer(val);
  };
  const reduxState = useSelector(({ auth, common }) => {
    return {
      user: auth.user,
      allClients: common.allClients,
      getClientsLoading: common.getClientsLoading,
      selectedClient: common.selectedClient,
    };
  });

  const { user, allClients, getClientsLoading, selectedClient } = reduxState;

  useEffect(() => {
    dispatch(getNotifications())
  }, [])

  const renderCompany = () => {
    return (
      <CField
        containerClass="company-drop"
        placeholder={user?.companyName}
        placeHolderHide={true}
        isLabel={'Select'}
        value={selectedClient}
        disabled={getClientsLoading || companyLoading}
        loading={getClientsLoading}
        data={allClients?.length ? [...allClients] : []}
        fieldType="select"
        renderSelectedKey="companyName"
        onChange={(val) => dispatch(changeMainClient(val))}
      />
    );
  };

  const renderTitle = () => {
    if (title) {
      return title;
    } else {
      return sayHi();
    }
  };
  const renderSubTitle = () => {
    if (subTitle) {
      return subTitle;
    } else {
      return subTitleToTitle ? sayHi() : "";
    }
  };

  const sayHi = () => {
    return "List of All Employees, you can update/upload excel or pdf file to update employees data";
    // return `Hi ${reduxState?.user?.name}!`
  };

  const menu = (obj) => {
    return (
      <Menu className="header-dropdown">
        <Menu.Item key="0" onClick={() => null}>
          Update
        </Menu.Item>
        <Menu.Item key="1"> Share with all students </Menu.Item>
        <Menu.Item key="2"> Share with whole class </Menu.Item>
        <Menu.Item key="3"> Share with... </Menu.Item>
      </Menu>
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  const renderGoBack = () => {
    return <ImArrowLeft2 />; //<CIcon icon='thin-arrow-left' className='c-icon' onClick={goBack} />
  };

  return (
    <div>
      <div
        className="navbar"
        style={{
          background: navbarBg,
        }}
      >
        <button
          className="menu-button"
          onClick={() => dispatch(toggleLeftDrawer(true))}
        >
          <IoIosMenu />
        </button>
        {canGoBack && (
          <button className="back-button" onClick={goBack}>
            {renderGoBack()}
          </button>
        )}
        <div className="main-nav">
          <div className="navbar-left">
            <h1>{subTitleToTitle ? renderSubTitle() : renderTitle()}</h1>
            <p>{subTitleToTitle ? sayHi() : renderSubTitle()}</p>
          </div>
          {canRenderCompany && user?.isParentCompany && allClients?.length ? renderCompany() : null}
          {showAjmanLogo ? (
            <div className="navbar-right">
              <Space size="middle" onClick={() => toggleNotificationsDrawer(true)}>
                <Badge className="notificationIcon" count={0}>
                  <BsBell size={34} className="c-icon notification" />
                </Badge>

              </Space>
              {/* <CImage
                url={require("../../assets/images/ajman-bank-logo.png")}
                className="ajmanBankLogo"
              /> */}
            </div>
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-between">
        {renderBelow ? renderBelow : <div></div>}
        {renderRight() ? (
          <div className="navbar-right">{renderRight()}</div>
        ) : null}
      </div>
      <Drawer
        title={`Notifications`}
        placement="right"
        className="custom-size"
        destroyOnClose={true}
        // size="medi"
        onClose={() => !reduxState?.actionLoading && toggleNotificationsDrawer()}
        open={isOpenNotificationsDrawer}
      >
        <NotificationDrawer
          onClose={toggleNotificationsDrawer}
          // selectedData={selectedData}
          // get={get}
          loading={reduxState?.actionLoading}
        // takeAction={takeAction}
        />
      </Drawer>
    </div>
  );
}
export default React.memo(Navbar);
