import ADVANCE_SALARY from "../constants/AdvanceSalary.constant";

const initialState = {
    advanceSalary: [],
    loading: false,
    metaData: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case ADVANCE_SALARY.ADVANCE_SALARY_GET_API:
            return {
                ...state,
                advanceSalary: action.data ? action.data : !action.data?.length ? [] : state.advanceSalary,
                metaData: action.metaData || state.metaData,
                loading: action.loading
            };

        default:
            return state;
    }
};