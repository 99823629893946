import TRANSACTIONS from "./../constants/Transactions.constant";

const initialState = {
  getLoading: true,
  transactions: [],
  metaData: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case TRANSACTIONS.GET_TRANSACTIONS:
      return {
        ...state,
        getLoading: action.loading,
        transactions: action.data || state.transactions,
        metaData: action.metaData || state.metaData
      };

    default:
      return state;
  }
};
