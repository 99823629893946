import React from "react";
import { Popover, Steps } from "antd";

const CSteps = ({ current = 0, items = [], style = {}, status = 'process' }) => {
  return (
    <Steps
      current={current}
      progressDot={(dot, { status, index }) => (
        // <Popover
        //   content={
        //     <span>
        //       step {index} status: {status}
        //     </span>
        //   }
        // >
        dot
        // </Popover>
      )}
      responsive={true}
      style={style}
      status={status}
      size={"small"}
      items={items}
    />
  );
};

export default CSteps;
