import React, { useState } from "react";
import { CButton } from "../../uiComponents";
import { FaUsers } from "react-icons/fa";
import { ConditionalRendering, getBase64, handleError, renderDateAndTimeFile, uploadFile } from "../../utils/methods";
import { useDispatch, useSelector } from "react-redux";
import { ACL_ACCESS_LIST, ACL_MODULES, IsAllowedOption } from "../../utils/acl";
import { arrayToBlob, getBulkDeactivationTemplate, getLength } from "./helper";
import { CSV_FILETYPES, EXCEL_FILETYPES } from "../../utils/constants";
import { deactivateBulkEmployees, processBulkDeactivationFile } from "../../store/actions/Employees.action";
import ValidEmployees from "./ValidEmployees";
import InvalidEmployees from "./InvalidEmployees";

const CBulkDeactivation = () => {

    const dispatch = useDispatch();
    const canDeactivate = IsAllowedOption(ACL_MODULES.EMPLOYEES, ACL_ACCESS_LIST.DEACTIVATE_EMPLOYEES);

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.processBulkDeactivationFileLoading,
        };
    });

    const { loading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenInvalid, setIsOpenInvalid] = useState(false);
    const [originalFile, setOriginalFile] = useState({});
    const [validateEmployees, setValidateEmployees] = useState([]);
    const [invalidateEmployees, setInvalidateEmployees] = useState([]);
    const [validEmployeeIds, setValidEmployeeIds] = useState([]);
    const [invalidEmployeesErrorFile, setInvalidEmployeesErrorFile] = useState({});

    const handleFile = async (file) => {
        if (file[0]?.name?.includes("xls") || file[0]?.name?.includes("xlsx") || file[0]?.name?.includes("csv")) {
            await getBase64(file[0]).then((uri) => {
                file[0].thumbUrl = uri;
                setOriginalFile(file[0]);
                processFile(file[0]);
            });
        } else {
            handleError("Only Excel file is allowed!");
        }
    };

    const callBackProcessFile = async (response) => {
        const { validEmployees, invalidEmployees, validEmployeeIds } = response;
        setValidateEmployees(validEmployees);
        setInvalidateEmployees(invalidEmployees);
        setValidEmployeeIds(validEmployeeIds);
        setIsOpen(true);
        if (getLength(invalidEmployees)) {
            await arrayToBlob(invalidEmployees).then(async (blob) => {
                let invalidEmployeesFile = await new File([blob], `bulk-deactivation-error-file-${renderDateAndTimeFile()}.csv`, { type: "text/csv" });
                await getBase64(invalidEmployeesFile).then((uri) => {
                    invalidEmployeesFile.thumbUrl = uri;
                    setInvalidEmployeesErrorFile(invalidEmployeesFile);

                });
            });
        }
    }

    const processFile = (file) => {
        const body = new FormData();
        body.append('employees', file);
        dispatch(processBulkDeactivationFile(body, callBackProcessFile));
    };

    const submitDeactivateEmployees = () => {
        const body = new FormData();
        body.append('originalFile', originalFile);
        if (validEmployeeIds?.length) {
            validEmployeeIds.forEach(element => {
                body.append('employeeIds', element);
            });
        }
        body.append('errorFile', invalidEmployeesErrorFile);
        dispatch(deactivateBulkEmployees(body, () => setIsOpen(false)));
    }

    return <>
        <ConditionalRendering
            condition={canDeactivate}
            children={
                <>
                    <CButton
                        buttonLayout="primary-outline"
                        title="Employees Deactivation Template"
                        type="button"
                        leftIcon="download"
                        onClick={getBulkDeactivationTemplate}
                        loading={loading}
                    />
                    <CButton
                        buttonLayout="primary"
                        title="Upload Deactivation"
                        onClick={() => uploadFile(handleFile, `${EXCEL_FILETYPES},${CSV_FILETYPES}`)}
                        leftIcon={(className) => <FaUsers className={className} />}
                        disabled={loading}
                    />
                </>
            }
        />
        <ValidEmployees isOpen={isOpen} setIsOpen={setIsOpen} validateEmployees={validateEmployees} invalidateEmployees={invalidateEmployees} setIsOpenInvalid={setIsOpenInvalid} submitDeactivateEmployees={submitDeactivateEmployees} />
        <InvalidEmployees isOpen={isOpenInvalid} setIsOpen={setIsOpenInvalid} invalidateEmployees={invalidateEmployees} />
    </>
};

export default CBulkDeactivation;