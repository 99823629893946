import io from "socket.io-client";
import { TOKEN } from "./asyncStorage/Constants";
import { getValueIntoLocalStorage } from "./asyncStorage/Functions";
import { socketUrl } from "./intercepter";

let socket = null;

const setGlobalSocketConnection = (data) => {
    socket = data;
};

const connectionSocket = async (uid = '') => {
    let token = await getValueIntoLocalStorage(TOKEN);
    let options = { withCredentials: false, transports: ['websocket'], auth: { token } };
    socket = io.connect(`${socketUrl}/`, options);
    socket.on('connect', () => {
        // console.log('EVENTS SOCKET CONNECTED');
        // console.info('EVENTS SOCKET CONNECTED');
        // const engine = socket.io.engine;
        // console.log(engine); // in most cases, prints "polling"

        // engine.once("upgrade", () => {
        //     // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
        //     console.info(engine.transport.name); // in most cases, prints "websocket"
        // });

        // engine.on("packet", ({ type, data }) => {
        //     // called for each packet received
        //     console.info('packet received', { type, data })
        // });

        // engine.on("packetCreate", ({ type, data }) => {
        //     // called for each packet sent
        //     console.info('packet sent', { type, data })
        // });

        // engine.on("drain", () => {
        //     // called when the write buffer is drained
        //     console.info('drain')
        // });

        // engine.on("close", (reason) => {
        //     // called when the underlying connection is closed
        //     console.info('close', reason)
        // });
    });
    socket.on('connect_error', (err) => {
        // console.log('EVENTS SOCKET ERROR', { err });
        // console.info('EVENTS SOCKET ERROR', { err });
    });
    socket.on('disconnect', () => {
        // console.log('EVENTS SOCKET DISCONNECTED');
        // console.info('EVENTS SOCKET DISCONNECTED');
    });
    socket.on("details", (...args) => {
        // console.log('args', args)
    });
    socket.io.on("reconnect_attempt", () => {
        // console.info('reconnect_attempt')
    });

    socket.io.on("reconnect", () => {
        // console.info('reconnect')
    });
};



export { connectionSocket, socket, setGlobalSocketConnection }
