import CHILDS from "../constants/Childs.constant";

const initialState = {
    getLoading: false,
    childs: [],
    metaData: {},

    addLoading: false,
    updateLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case CHILDS.GET_CHILDS:
            return {
                ...state,
                getLoading: action.loading,
                childs: action.data,
                metaData: action.metaData
            };

        case CHILDS.ADD_CHILD:
            return {
                ...state,
                addLoading: action.loading,
            };

        case CHILDS.UPDATE_CHILD:
            return {
                ...state,
                updateLoading: action.loading,
            };

        default:
            return state;
    }

};
