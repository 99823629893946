const CommonConstant = {
  TOGGLE_LEFT_DRAWER: "TOGGLE_LEFT_DRAWER",
  
  CURRENT_COUNTRY: 'CURRENT_COUNTRY',

  GET_COUNTRIES: "GET_COUNTRIES",

  GET_STATES: "GET_STATES",

  SELECTED_CLIENT: "SELECTED_CLIENT",

  DOWNLOAD_CSV: "DOWNLOAD_CSV",

  GET_ALL_CLIENTS: 'GET_ALL_CLIENTS',

  GET_ALL_TOPICS: 'GET_ALL_TOPICS',

  REFRESH_TOKEN_TIMER: "REFRESH_TOKEN_TIMER",

};

export default CommonConstant;
