import axios from "axios";
import { TOKEN } from "./asyncStorage/Constants";
import {
  getValueIntoLocalStorage,
  setValueInToLocalStorage,
} from "./asyncStorage/Functions";
import { logout } from "../store/actions/Auth.action";
import { store } from "../store";

let baseUrl = `${process.env.REACT_APP_BASE_URL}/api`; // for Upload

export const socketUrl = process.env.REACT_APP_BASE_URL; //for Upload

const interceptor = () => {
  axios.defaults.baseURL = baseUrl;

  axios.interceptors.request.use(
    function (config) {
      const accessToken = localStorage.getItem(TOKEN);
      if (!accessToken) {
        store.dispatch(logout());
      } else {
        config.headers.authorization = accessToken;
      }
      return config;
    },
    function (error) {
      // console.log('error', error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      (async () => {
        if (
          response?.data?.error &&
          response?.data?.data?.message === "Session expired."
        ) {
          store.dispatch(logout(null, "expire", response?.data?.data?.message));
        } else {
          const isLoggedIn = store.getState()?.auth?.isLoggedIn;
          let token = '';
          if (isLoggedIn) {
            token = response?.data?.data?.token
          }

          if (token) {
            localStorage.setItem(TOKEN, token);
          }
        }
      })();
      return response;
    },
    function (error) {
      // console.log('error message', error);
      if (error?.response?.status === 401) {
        store.dispatch(logout(true, "expire", error?.response?.data?.message));
      }
      return Promise.reject(error?.response);
    }
  );
};

const getTokenAndSetIntoHeaders = async (token) => {
  if (token) {
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
  } else {
    let accessToken = await getValueIntoLocalStorage(TOKEN);
    if (accessToken) {
      axios.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;
    }
  }
};

export { baseUrl, interceptor, getTokenAndSetIntoHeaders };
