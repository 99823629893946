import React, { useEffect, useState } from "react";
import { CButton, CLoading, CPopup } from "../../uiComponents";
import { ACTION, CLIENT_APPROVAL_STATUS, TOPIC } from "../../utils/constants";
import {
    formatter,
    MappedElement,
    renderDateAndTime,
    renderDocuments,
} from "./../../utils/methods";
import { renderDate } from "../../utils/methods";
import moment from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { Empty } from "antd";

function NotificationDrawer({

}) {
    const dispatch = useDispatch();
    const reduxState = useSelector(({ notifications }) => {
        return {
            loading: notifications?.getLoading,
            notifications: notifications?.notifications,
        };
    });

    console.log({ reduxState })
    const notificationsData = () => {

        return <div>
            {reduxState?.notifications?.map((item) => {
                return <div className="singleNotification">
                    <div>
                    <span>{item?.message}</span>
                    <p>{renderDateAndTime(item?.createdAt)}</p> </div>
                    <hr />
                </div>
            })}
        </div>

    }


    return reduxState?.loading ? (
        <CLoading position="relative" className="h-100" />
    ) : (
        <>
            <div className="c-data col">

                {notificationsData()}
            </div>
        </>
    )

}
export default NotificationDrawer;
