import NOTIFICATIONS from "../constants/Notifications.constant";

const initialState = {
  getLoading: true,
  notifications: [],
  metaData: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case NOTIFICATIONS.GET_NOTIFICATIONS:
      return {
        ...state,
        getLoading: action.loading,
        notifications: action.data || state.notifications,
        metaData: action.metaData || state.metaData
      };

    default:
      return state;
  }
};
